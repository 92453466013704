import React, { useEffect } from "react"
import "../sass/main.scss"
import {
  Layout,
  SEO,
  HeadSection,
  ImageSection,
  Carousel,
  Scrollable,
  Image,
} from "../components"
import { Row, Col, Container } from "react-bootstrap"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import $ from "jquery"
import Slugify from "slugify"

const DeliveryPage = () => {
  useEffect(() => {
    $(".client").hover(
      function() {
        $(this).addClass("show")
      },
      function() {
        $(this).removeClass("show")
      },
    )
  })
  const headTitle = (): string => {
    return "Satalia Delivery"
  }
  const headSubTitle = (): string => {
    return "Last-mile Vehicle Routing. Optimised."
  }
  const headDescription = (): string => {
    return `Supply chains are complex, expensive and full of inefficiencies. There are 620,448,401,733,239,000,000,000 possible routes between 24 locations. Searching through all of those possibilities, it would take a computer 2 trillion years to find the optimal route. And that doesn't include the complexity of shift patterns, vehicle capacities or any other real-world constraints. Solving this manually is hopeless, and using generic, off-the-shelf systems isn’t much better.`
  }
  const imageDescription = () => {
    return (
      <Col
        xs="3"
        sm="7"
        md="8"
        lg="9"
        className="z-index-2 mb-150 offset-lg-2 offset-md-2 offset-sm-1"
      >
        <div className="st-block st-block--transparent st-block--no-padding">
          <h3>
            Optimise the routes and schedules of any vehicle fleet.
            <span className="font-weight-medium">
              Our clients claim Satalia Delivery is the best last-mile delivery
              solution in the world.
            </span>
          </h3>
        </div>
      </Col>
    )
  }

  const imageContent = () => {
    return (
      <Row>
        <Col xs="3" sm="8" md="10" lg="12" xl="9">
          <div className="efficiency__row">
            <p className="font-weight-bold">Improved efficiency.</p>
            <p>11.2 million miles saved</p>
            <p className="text-inherit">
              Reduce your costs and increase your revenue by using your drivers
              and vehicles more efficiently.
            </p>
          </div>
          <div className="efficiency__row">
            <p className="font-weight-bold">Employee satisfaction.</p>
            <p>19% reduction in overtime</p>
            <p className="text-inherit">
              Reduce administration for operators and customer service teams.
              Minimise unforeseen overtime for drivers.
            </p>
          </div>
          <div className="efficiency__row">
            <p className="font-weight-bold">Happier customers.</p>
            <p>8% increase in NPS</p>
            <p className="text-inherit">
              Increase net promotor score by offering customers a greater choice
              of more reliable time slots.
            </p>
          </div>
          <Link to="/clients/tesco" className="learn-more" title="Tesco">
            View the Tesco case study
          </Link>
        </Col>
      </Row>
    )
  }
  const data: any = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { eq: "services" } }) {
        edges {
          node {
            id
            publicURL
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
            name
            extension
          }
        }
      }
      caseStudies: allFile(
        filter: { relativeDirectory: { eq: "casestudies/featured_images" } }
      ) {
        edges {
          node {
            id
            publicURL
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
            name
            extension
          }
        }
      }
      placeholder: file(relativePath: { eq: "placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clients: allClient(
        limit: 3
        filter: {
          client_category_id: { eq: 1 }
          show_in_category_page: { eq: 1 }
        }
      ) {
        edges {
          node {
            id
            title
            logo
            industry
            testimonial_commenter_position
            testimonial_commenter
            testimonial_comment
            featured_image
          }
        }
      }
    }
  `)
  const getImage = (imageName: string) => {
    let image = data.images.edges.find((x: any) => x.node.name === imageName)
    if (!image) {
      image = data.placeholder.childImageSharp.fluid
      return image
    }
    return image ? image.node.childImageSharp.fluid : null
  }
  return (
    <Layout step={2}>
      <SEO
        title="Satalia Delivery | Optimised Last Mile Routing "
        description="Satalia Delivery optimises the routes and schedules of any vehicle fleet. Saves money, reduces environmental impact and improves customer choice."
        twitterTitle="Satalia Delivery | Optimised Last Mile Routing "
        twitterDescription="Satalia Delivery optimises the schedules of any vehicle fleet. Saves money, reduces emissions and improves customer choice."
        openGraphTitle="Satalia Delivery | Optimised Last Mile Routing "
        openGraphDescription="Last mile vehicle routing and scheduling. Optimised."
      />
      <Container>
        <section id="satalia-delivery" className="mb-95">
          <HeadSection
            title={headTitle()}
            subtitle={headSubTitle()}
            description={headDescription()}
          ></HeadSection>
        </section>
        <section className="mb-285" id="efficiency">
          <ImageSection
            customClass="efficiency--unified"
            description={imageDescription()}
            content={imageContent()}
            image="satalia-delivery"
            align="end"
            aos="fade-right"
          ></ImageSection>
        </section>
        <section id="features" className="mb-285">
          <Row className="justify-content-center align-items-center">
            <Col xs="3" sm="8" md="10" lg="6" xl="6" className="mb-45">
              <Carousel activeIndex={0} autoPlay={true}>
                <Row className="justify-content-center">
                  <Col xs="3" sm="6" md="6" lg="12" xl="12">
                    <Carousel.Title key="title">Features</Carousel.Title>
                  </Col>
                </Row>
                <div className="st-block">
                  <Carousel.Tabs key="tabs">
                    <Row className="justify-content-sm-center">
                      <Col
                        xs="3"
                        sm="2"
                        md="2"
                        lg="4"
                        xl="4"
                        className="st-carousel__tab-column"
                      >
                        <Carousel.Tab>
                          <h3>
                            Dynamic
                            <br /> optimisation
                          </h3>
                        </Carousel.Tab>
                      </Col>
                      <Col
                        xs="3"
                        sm="2"
                        md="2"
                        lg="4"
                        xl="4"
                        className="st-carousel__tab-column"
                      >
                        <Carousel.Tab>
                          <h3>
                            Performance
                            <br /> at scale
                          </h3>
                        </Carousel.Tab>
                      </Col>
                      <Col
                        xs="3"
                        sm="2"
                        md="2"
                        lg="4"
                        xl="4"
                        className="st-carousel__tab-column"
                      >
                        <Carousel.Tab>
                          <h3>
                            Real-world
                            <br /> constraints
                          </h3>
                        </Carousel.Tab>
                      </Col>
                    </Row>
                    <Carousel.Navigations>
                      <li
                        className="st-carousel__navigation st-carousel__navigation--left st-carousel__navigation--disabled"
                        data-navigate="previous"
                      ></li>
                      <li
                        className="st-carousel__navigation st-carousel__navigation--right"
                        data-navigate="next"
                      ></li>
                    </Carousel.Navigations>
                  </Carousel.Tabs>
                  <Row className="justify-content-center">
                    <Col xs="3" sm="6" md="6" lg="12" xl="12">
                      <Carousel.Contents key="contents">
                        <Carousel.Content>
                          <p>
                            Instead of optimising deliveries in bulk at the end
                            of the day, Satalia Delivery re-optimises the
                            schedule every time an order is made. This maximises
                            the utility of the fleet, and the number of
                            time-slots that can be offered to customers.
                          </p>
                        </Carousel.Content>
                        <Carousel.Content>
                          <p>
                            Satalia Delivery is cloud-based, and performs at
                            scale. It’s built by a world class team of
                            optimisation, geospatial and engineering talent, and
                            uses the latest algorithms from academia. It’s
                            trusted by the likes of Tesco, DFS and Woolworths
                            (Australia).
                          </p>
                        </Carousel.Content>
                        <Carousel.Content>
                          <p>
                            Satalia Delivery accounts for real-world
                            constraints, including shift patterns, travel
                            speeds, vehicle capacity, vehicle types, order
                            weight and volumetrics. We’re able to rapidly
                            develop custom constraints to solve 100% of your
                            problem.
                          </p>
                        </Carousel.Content>
                      </Carousel.Contents>
                    </Col>
                  </Row>
                </div>
              </Carousel>
            </Col>
            <Col xs="3" sm="8" md="8" lg="6" xl="6" data-aos="fade-left">
              <div className="st-block st-block--has-top-padding">
                <Img loading="eager" fluid={getImage("delivery-ui")} />
              </div>
            </Col>
          </Row>
        </section>
        <section id="delivery-analysis" className="mb-285">
          <Row>
            <Col
              xs="3"
              sm="8"
              md="8"
              lg="9"
              xl="9"
              className="z-index-2 mb-150"
            >
              <div className="st-block st-block--transparent st-block--no-padding">
                <h3>
                  We build custom machine learning modules that enhance data
                  quality,{" "}
                  <span className="font-weight-medium">
                    support decision making and further increase the schedules
                    efficiency.
                  </span>
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="3" sm="8" md="10" lg="12" xl="9">
              <Row>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium p-0">
                      Time-at-door analysis
                    </p>
                    <p>
                      Uses product, telematics and building data to predict
                      accurate time-at-door. Improves schedule efficiency.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium p-0">
                      Shift Pattern Optimisation
                    </p>
                    <p>
                      Predicts shift patterns that better match supply with
                      demand. Improves employee effectiveness and satisfaction.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium p-0">Demand Forecasting</p>
                    <p>
                      Uses historical data to predict demand for any given
                      location. Enables improved pricing and resourcing
                      decisions.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium p-0">
                      Slot Cost Prediction
                    </p>
                    <p>
                      Uses environmental data sources to predict future demand
                      so to accurately calculate slot costs and drive customer
                      behaviour.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium p-0">
                      Supplier Reliability
                    </p>
                    <p>
                      Better predicts when products will enter the warehouse.
                      Used to optimise throughput and onward distribution.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium p-0">Real Time Tracking</p>
                    <p>
                      Uses real-time route information to enable adaptive
                      machine learning to compare actual versus planned trips.
                      Improves compliance and decision-making.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section id="how-it-works" className="mb-285">
          <Row className="justify-content-end">
            <Col xs="3" sm="8" md="10" lg="9" xl="9">
              <Row className="justify-content-md-end">
                <Col
                  xs="3"
                  sm="4"
                  md="3"
                  lg="4"
                  xl="4"
                  className="mb-45"
                  data-aos="fade-up"
                  data-aos-delay="0"
                >
                  <div className="st-block">
                    <h2>How it works?</h2>
                    <p>
                      We have two different engagement models, depending on the
                      size and complexity of your supply chain problem.
                    </p>
                    <Link className="learn-more" to="/contact" title="Contact">
                      Get in touch
                    </Link>
                  </div>
                </Col>
                <div className="w-100 d-md-none"></div>
                <Col xs="3" sm="4" md="3" lg="4" xl="4">
                  <div
                    className="st-block"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    {getImage("delivery-custom-components") ? (
                      <Img
                        loading="eager"
                        fluid={getImage("delivery-custom-components")}
                        style={{ paddingBottom: "33.2%" }}
                      />
                    ) : null}
                    <p className="font-weight-medium">Custom components</p>
                    <p>
                      We meet with various stakeholders to understand your
                      objectives, rules, constraints and other moving parts.
                      Then we customise our core components, or build new
                      functionality to solve 100% of your problem. This
                      typically suits larger, more complex operations.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="3" lg="4" xl="4">
                  <div
                    className="st-block"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    {getImage("delivery-efficiency-as-service") ? (
                      <Img
                        loading="eager"
                        fluid={getImage("delivery-efficiency-as-service")}
                        style={{ paddingBottom: "33.2%" }}
                      />
                    ) : null}
                    <p className="font-weight-medium">Saas product</p>
                    <p>
                      Usable immediately as an off-the-shelf solution. Teams can
                      setup and manage their operation directly via our set of
                      interfaces, or through their existing systems via our API.
                      This typically suits the smaller, less complex operations.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section id="landing-app" className="mb-285">
          <Row>
            <Col xs="3" sm="8" md="10" lg="6" xl="6" className="mb-45">
              <div className="st-block">
                <h2>Optimise your delivery</h2>
                <p>
                  Enter details about your vehicle fleet and orders, and watch
                  as Satalia optimises the deliveries to create the most
                  efficient schedule.
                </p>
                <p>
                  Please contact us if you are not based in the UK and would
                  like to use this demo.
                </p>
                <Link className="learn-more" to="/contact" title="Contact">
                  Get in touch
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col>
              <iframe
                className={"deliveryAppIframe"}
                style={{ border: "none" }}
                id="inlineFrameExample"
                title="Inline Frame Example"
                width="100%"
                src="https://dev.delivery.satalia.io/v2/landing-page"
              ></iframe>
            </Col>
          </Row>
        </section>
      </Container>
      <Container fluid>
        <section id="case-studies" className="pb-75">
          <Container className="p-0">
            <h2>Case studies</h2>
          </Container>
          <Scrollable
            itemSelector=".case-studies__item"
            section="#case-studies"
            leftIndex={0}
          >
            <Row className="case-studies__items st-scroll">
              <div className="scroll-padding"></div>
              {data.clients.edges.map((client, index) => (
                <div className="case-studies__item" key={index}>
                  <ClientItems client={client.node}></ClientItems>
                </div>
              ))}
              <div className="scroll-padding"></div>
            </Row>
          </Scrollable>
        </section>
      </Container>
    </Layout>
  )
}

const ClientItems = ({ client }) => {
  return (
    <div className="client">
      <div className="st-block">
        <Image
          path={client.featured_image}
          isSquare={true}
          alt={"Client | " + client.title}
        />
        <div className="client__content">
          <Row className="h-100">
            <Col xl="12" className="mt-auto">
              <span className="client__category text-white">
                {client.industry}
              </span>
              <h3 className="text-white">{client.title}</h3>
              <div className="client__comment">
                <p>{client.testimonial_comment}</p>
                <p className="font-weight-medium client__commenter">
                  {client.testimonial_commenter}
                </p>
                <Link
                  to={"/clients/" + Slugify(client.title.toLowerCase())}
                  className="learn-more"
                  title={client.title}
                >
                  Read more
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
export default DeliveryPage
